// This file includes components used by our client facing UI.
// Please lazily load large components.

import {
  BudgetDisplayPill,
  ContactUs,
  DashboardDebugBox,
  DisplayTagList,
  DropshipEditor,
  LookbookProductFilter,
  OrderRecipientDelivery,
  ProductDetail,
  ReactionStatistics,
  RequestCart,
  SelectionItemWarnings,
  SignIn,
  Spreadsheet,
  StorefrontOrderHistoryControls,
  StoreKitInstanceForm,
  TeamForm,
  TeamsTable,
  ThankYouNoteConfirmation,
  ThankYouNoteLanding,
  ThankYouNoteMessageCarousel,
  ThankYouNotesIndex,
} from "~/src/components"
import { ErrorPage } from "~/src/components/ErrorPage"
import { NavbarProjectFolder } from "~/src/components/NavbarProjectFolder"
import { SolutionInstanceToggle } from "~/src/components/Solutions/SolutionInstanceToggle"
import { registerComponents } from "~/src/lib/reactComponentRegistry"

registerComponents({
  BudgetDisplayPill,
  ContactUs,
  Controller: import("~/src/components/Controller/Controller"),
  CustomTraySolutionForm: import("~/src/components/Solutions/CustomTraySolutionForm"),
  CustomTraySolutionSelectorForm: import("~/src/components/Solutions/CustomTraySolutionSelectorForm"),
  DashboardDebugBox,
  DashboardToaster: import("~/src/components/DashboardToaster"),
  DisplayTagList,
  DropshipEditor,
  ErrorPage,
  LookbookProductFilter,
  NavbarProjectFolder,
  OrderRecipientDelivery,
  ProductDetail,
  ReactionStatistics,
  RequestCart,
  SignIn,
  SolutionInstanceToggle,
  Spreadsheet,
  StorefrontOrderHistoryControls,
  StoreKitInstanceForm,
  SelectionItemWarnings,
  StripeFieldSet: import("~/src/components/StripeFieldSet"),
  TeamForm,
  TeamsTable,
  ThankYouNoteConfirmation,
  ThankYouNoteLanding,
  ThankYouNoteMessageCarousel,
  ThankYouNotesIndex,
})
