import Alpine from "alpinejs"

import { isntNil } from "~/src/lib/any"
import { appClient } from "~/src/lib/appClients"

type Preference = {
  name: string
  required: boolean
  response?: string
  preferenceResponseId?: string
  parentProps?: ParentProperties
  responsesNotRequiringDelivery: { [key: string]: string }
}

type AddedPreference = { key: string } & Partial<Preference>

type PreferenceRecord = { [key: string]: Preference }

type ParentProperties = { id: string; matchingPreferenceResponseId: string; matchingResponse: string }

type DataObject = {
  optedOut: boolean
  invalidPreferences: Preference[]
  preferences: PreferenceRecord
  init: () => void
  addPreference: (fields: AddedPreference) => void
  clearResponses: () => void
  validate: (preference: Preference) => boolean
  isValidPreferences: () => boolean
  isDeliveryRequired: () => Promise<boolean>
  displayName: (preference: Preference) => string
  registerPreference: { ["x-init"]: () => void }
  doSelectedResponsesRequireDelivery: (selectedPrefs: Preference[]) => boolean
  getSelectedPreferences: (prefs: PreferenceRecord) => Preference[]
}

type RecipientFormOptions = {
  optedOut: boolean
  _flag_cx_is_delivery_required: boolean
}

export function recipientFormData(alpine: typeof Alpine) {
  alpine.data("orderRecipient", function (options: RecipientFormOptions): DataObject {
    const { optedOut, _flag_cx_is_delivery_required = false } = options

    return {
      optedOut: optedOut,
      invalidPreferences: [],
      preferences: {
        default_variant: {
          name: "Default",
          required: false,
          response: "ok",
          responsesNotRequiringDelivery: {},
        },
      },

      init() {
        this.$watch("preferences", (prefs: { [key: string]: Preference }) => {
          this.invalidPreferences = Object.values(prefs).filter((pref) => !this.validate(pref))

          // If the user has opted out then selects a response, opt them back in
          if (this.optedOut === true) {
            const hasResponded = Object.values(prefs).some((pref) => {
              return isntNil(pref.response || pref.preferenceResponseId)
            })

            if (hasResponded) this.optedOut = false
          }
        })

        this.$watch("optedOut", (optedOut: boolean) => {
          if (optedOut === true) this.clearResponses()
        })

        if (_flag_cx_is_delivery_required) {
          requestAnimationFrame(() => {
            this.isDeliveryRequired().then((deliveryRequired: boolean) => {
              this.showAddressFields = deliveryRequired
            })
          })
        }
      },

      //region Remove with :cx_is_delivery_required
      getSelectedPreferences(prefs: { [key: string]: Preference }) {
        return Object.values(prefs).filter(
          (pref) => pref.preferenceResponseId != null && pref.preferenceResponseId?.trim() != ""
        )
      },

      doSelectedResponsesRequireDelivery(selectedPrefs: Preference[]): boolean {
        // If _any_ selected pref response requires delivery (not an eGift for example) then delivery is required
        return selectedPrefs.some(
          ({ preferenceResponseId, responsesNotRequiringDelivery }) =>
            responsesNotRequiringDelivery == null ||
            !responsesNotRequiringDelivery.hasOwnProperty(String(preferenceResponseId))
        )
      },
      //endregion

      async isDeliveryRequired(): Promise<boolean> {
        if (_flag_cx_is_delivery_required === false) {
          return this.doSelectedResponsesRequireDelivery(this.getSelectedPreferences(this.preferences))
        }

        const form = this.$refs.form

        if (!(form instanceof HTMLFormElement))
          throw new TypeError(`Expected form reference to be a HTMLFormElement, but got ${form.toString()}`)

        const body = Object.fromEntries(
          Array.from(new FormData(form).entries()).filter(
            ([x]) => x.includes("order_preferences_attributes") || x.includes("slug")
          )
        )

        try {
          const response = await appClient.post("/recipients/delivery_required", body, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          })
          return response.data.deliveryRequired
        } catch (error) {
          // @ts-expect-error Rollbar is loaded in a head script tag
          window?.Rollbar?.error(error)
          return true
        }
      },

      registerPreference: {
        ["x-init"]() {
          this.addPreference(JSON.parse(this.$el.dataset.props))
        },
      },

      addPreference(fields) {
        const { key, response, preferenceResponseId, ...restPreference } = fields

        this.preferences[key] = {
          ...restPreference,
          response: response?.toString(),
          preferenceResponseId: preferenceResponseId?.toString(),
        }
      },

      clearResponses() {
        const newPreferences: PreferenceRecord = {}

        Object.entries<Preference>(this.preferences).forEach(([key, preference]) => {
          newPreferences[key] = { ...preference, response: undefined, preferenceResponseId: undefined }
        })

        this.preferences = newPreferences
      },

      validate(preference) {
        const { required, response, preferenceResponseId, parentProps } = preference

        if (parentProps != null) {
          const parentPreference = this.preferences[parentProps.id]

          // Abort if child but parent can't be found
          if (parentPreference == null) return true

          // Abort if parent preference response for this child isn't selected
          if (parentPreference.preferenceResponseId != parentProps.matchingPreferenceResponseId) {
            return true
          }
        }

        // Always valid if not required
        if (required == false) return true

        // Valid if a response or a preferenceResponseId is set
        return (
          (preferenceResponseId != null && preferenceResponseId.trim() != "") ||
          (response != null && response.trim() != "")
        )
      },

      isValidPreferences(): boolean {
        return this.invalidPreferences.length == 0
      },

      displayName(preference: Preference): string {
        const { parentProps } = preference

        if (isntNil(parentProps)) {
          const parentPreference = this.preferences[parentProps.id]

          if (isntNil(parentPreference)) {
            return parentPreference.name + " - " + parentProps.matchingResponse + ": " + preference.name
          }
        }

        return preference.name
      },
    }
  })
}
